import React from "react"
import { Link } from "gatsby"

import  "../../node_modules/nes.css/css/nes.min.css"
import { rhythm } from "../utils/typography"
import Marquee from "react-marquee"
import Menu from "./menu"
import "typeface-press-start-2p"
import './layout.css'
import logo from '../../content/assets/powerglove.png'

console.log(logo);

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

      header = (
        <div
          className={ location.pathname === rootPath ? "logo-sitename homepage" : "logo-sitename" }
          style={{
          }}
        >
          <Link
            className="logo"
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <img className="glove-shimmer" src={logo} alt="A pixel art drawing of a Power Glove" />
          </Link>
          <h1
            style={{
              marginTop: 0,
              fontFamily: "Press Start 2P"
            }}
            className="shimmer"
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
        </div>
      )
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `0 ${rhythm(1.5)} ${rhythm(3 / 4)}`,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <hr className="pixelhr" />
        <Marquee loop={true} hoverToStop={true} className="pixelmarquee" text="CHIPTUNE RADIO CHIPTUNE RADIO CHIPTUNE RADIO CHIPTUNE RADIO"></Marquee>
        <header>{header}</header>
        <Marquee loop={true} hoverToStop={true} className="pixelmarquee" text="KSUA 91.5 FM KSUA 91.5 FM KSUA 91.5 FM KSUA 91.5 FM KSUA 91.5 FM"></Marquee>
        <hr className="pixelhr" />
        <Menu />
        <main
          style={{
            flex: "1",
          }}
        >
          {children}
        </main>
        <footer
          style={{
            textAlign: "center",
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
          <br />
          Power Glove logo courtesy of <a href="http://b.illbrown.com/">Bill Brown</a>
        </footer>
      </div>
    )
  }
}

export default Layout
