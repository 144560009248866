/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import './menu.css'

function Menu() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menu {
              text
              url
            }
          }
        }
      }
    `
  )

  return (
    <nav className="nav--main">
    {site.siteMetadata.menu.map(( menuItem ) => {
      return (
        <Link className="nav__menu-item" to={menuItem.url}>{menuItem.text}</Link>
      )
    })}
    </nav>
  )
}

export default Menu


